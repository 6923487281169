import { SupportedCurrencies } from '@wanda-space/types'
import type { PriceWrapper } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'

export const Price = (props: {
  price: PriceWrapper | undefined
  recurrence?: 'MONTHLY' | 'MONTHLY_SHORT'
  hasDecimal?: false | true
}) => {
  const { formatMessage } = useIntl()

  let per = null
  switch (props.recurrence) {
    case 'MONTHLY':
      per = `/${formatMessage({ id: 'word.monthAbbreviation' })}`
      break
    case 'MONTHLY_SHORT':
      per = `/${formatMessage({ id: 'word.monthShort' })}`
      break
    default:
      per = ''
  }
  switch (props.price?.currency) {
    case SupportedCurrencies.SEK:
      return (
        <span>
          {props.price?.amount === 0
            ? props.price?.amount
            : props.hasDecimal
              ? props.price?.amount.toFixed(2)
              : props.price?.amount}{' '}
          {formatMessage({ id: 'word.kr', defaultMessage: SupportedCurrencies.SEK })}
          {per}
        </span>
      )

    default:
      return (
        <span>
          {props.price?.amount === 0
            ? props.price?.amount
            : props.hasDecimal
              ? props.price?.amount.toFixed(2)
              : props.price?.amount}{' '}
          {formatMessage({ id: 'word.kr' })}
          {per}
        </span>
      )
  }
}
