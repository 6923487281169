import { captureException } from '@sentry/react'
import type { CreateListingRequestBodyDto, ItemResponseDto } from '@wanda-space/types'
import {
  createListingForStorage,
  postListingImage,
  postListingPrimaryImage,
  updateListingItemImage,
} from 'api-client'
import type { ListingForPreviewType } from 'interfaces/listing'
import type { AppDispatch } from 'reduxStore'
import { ListingForStorage } from 'reduxStore/ducks/sell/sellFlow'
import { fetchUser } from 'reduxStore/ducks/user/user'
import { convertToOre } from 'utils'

const handleUploadImageForListing = async (files: string[], listingId: string) => {
  return Promise.all(
    files.map(async (file, index) => {
      const { imageId } = await postListingImage({ listingId, file })
      if (index === 0) {
        await postListingPrimaryImage(listingId, imageId)
      }
      return imageId
    })
  )
}

const handleUploadListingImageForItem = async (
  files: string[],
  listingId: string,
  itemIds: string[]
) => {
  const [primaryImage] = files
  if (primaryImage) {
    for (const itemId of itemIds) {
      await updateListingItemImage(listingId, itemId, primaryImage)
    }
  }
}

export async function createListingForStorageAndUploadImages(
  listingDetails: ListingForPreviewType<false>,
  storageItems: ItemResponseDto[],
  dispatch: AppDispatch
) {
  const {
    name,
    price,
    description,
    images,
    showOwnerName,
    conditionType,
    dimensionLength,
    dimensionHeight,
    dimensionWidth,
    dimensionFreeText,
    extraDescriptiveInfo,
  } = listingDetails
  const storageItemIds = storageItems.map(({ id }) => id)
  const mapPayload: CreateListingRequestBodyDto = {
    name,
    price: convertToOre(price.amount),
    currency: price.currency,
    storageItems: storageItemIds as [string, ...string[]],
    description: description,
    showOwnerName,
    conditionType,
    dimensionLength,
    dimensionHeight,
    dimensionWidth,
    dimensionFreeText,
    extraDescriptiveInfo,
    addOns: listingDetails.addons,
  }

  const { listing, serviceOrderIds } = await createListingForStorage(mapPayload)
  const order = {
    // This is primarily used for tracking purposes
    orderId: (Array.isArray(serviceOrderIds) && serviceOrderIds.join('-')) || null,
  }

  await dispatch(fetchUser()).unwrap()

  const hasImages = images && images.length > 0

  if (hasImages) {
    try {
      await handleUploadImageForListing(images, listing.id)
      await handleUploadListingImageForItem(images, listing.id, storageItemIds)
    } catch (error) {
      captureException(error)
    }
  }
  return { listing, order }
}
