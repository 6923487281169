import { getEnv } from 'env'
import TagManager from 'react-gtm-module'
import type { GA4PurchaseEvent, TrackerDTO } from 'tracking/types'

/**
 * Singleton to for centralized tracking of GTM events (DRY and SoC / SRP)
 * @class GTM_handler
 */
class GTM_handler {
  private static _instance: GTM_handler
  /** Not to be confused with the Do Not Track standard - will be set to true for logged-in users with wanda email address */
  private static _doNotTrack = false
  /** As a safeguard, we should also include the user_id parameter in all GA4 event tags. If, for some reason,
   * the Google Tag cannot access the user ID, all other GA4 event tags will still be able to do it. */
  private static _user_id: string | undefined = undefined
  private static _env = getEnv('APP_ENVIRONMENT') as 'prd' | 'dev' | 'local'

  /**
   * This is a temporary workaround, as long as we don't have access to the GTM container,
   * @see https://linear.app/wanda/issue/WAN-3013
   */
  private static _useLegacyPurchaseEventFormat = true

  private static _extractPurchase = (purch: NonNullable<TrackerDTO['_purchase']>) => {
    const purch_error = purch._spaceship_error || false
    const purch_is_valid = !purch_error && 'items' in purch && !!purch.items.length

    const extracted = purch_is_valid && {
      event: 'purchase',
      // ga4 format, see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#purchase-gtm
      ecommerce: purch as GA4PurchaseEvent,
    }

    // Keep for debugging, for now...
    /*
    console.groupCollapsed(
      'GTM_handler._extractPurchase (%s)',
      purch_is_valid ? 'VALID' : 'INVALID'
    )
    console.log('purch:', purch)
    purch_error && console.log('purch_error:', purch_error)
    console.log('purch_is_valid:', purch_is_valid)
    purch_is_valid && console.log('extracted:', extracted)
    console.groupEnd()
    */

    return extracted
  }

  /**
   * Takes a payload and...
   *  1. determines whether or not to track it based on its contents (returns false if not to be tracked)
   *  2. if to be tracked, extracts and return relevant data specific for GTM4
   *
   * Rationale:
   * - We don't want to track everything, only what's relevant
   * - We need to assure that the data we send is in the correct format
   */
  private static _filterAndExtract = (payload: TrackerDTO) => {
    // for now, we only want to track purchases...
    const purch = payload._event_type && payload._event_type === 'purchase' && payload._purchase
    const ga4Purchase = purch && GTM_handler._extractPurchase(purch)

    if (ga4Purchase) {
      if (GTM_handler._useLegacyPurchaseEventFormat) {
        // This is a temporary workaround, as long as we don't have access to the GTM container,
        // Using same format as in handleTagManagerPurchase

        /** order can contain different types of order lines - doesn't make sense */
        const orderType = 'could_be_mixed_see_products'
        const products = ga4Purchase.ecommerce.items.map((item) => {
          const product = {
            ...item,
            name: item.item_name,
            price: item.price,
            quantity: item.quantity || 1,
          }
          return product
        })

        return {
          ...ga4Purchase,
          event: 'purchase',
          userId: GTM_handler._user_id,
          countryCode: payload._country,
          // eventLabel was same as orderType ("pickup" or "delivery"), but that doesn't make sense
          eventLabel: payload._flow + (payload._flow_variant ? `-${payload._flow_variant}` : ''),
          ecommerce: {
            purchase: {
              actionField: {
                orderType: orderType,
                id: ga4Purchase.ecommerce.transaction_id,
                revenue: ga4Purchase.ecommerce.value,
                quantity: products.reduce((acc, cur) => acc + cur.quantity, 0),
              },
              products,
            },
          },
        }
      }
      return ga4Purchase
    }

    return false
  }

  private constructor() {
    // For testing GTM in development environment
    // See https://www.simoahava.com/analytics/better-qa-with-google-tag-manager-environments/#the-new-environment-name-variable
    // You can use this link to preview GTM events in development environment, using Tag Assistant:
    // https://tagassistant.google.com/?hl=en-GB&utm_source=gtm#/?source=TAG_MANAGER&id=GTM-MKH9LDKG&gtm_auth=YxcZDZO87z2sJZ7YhIBb0w&gtm_preview=env-5&cb=832291359432505
    if (GTM_handler._env === 'local') {
      console.info('[GTM_handler] GTM ininzialising, using preview for local environment')
      TagManager.initialize({
        gtmId: 'GTM-MKH9LDKG',
        preview: 'env-6', // for "local" environment in GTM, see container / gtmId in GTM
        auth: 'WxZmNxnkrv-zAtK1m7lbsg',
      })
      return
    }

    TagManager.initialize({
      gtmId: getEnv('GOOGLE_TAG_MANAGER_ID') || process.env.GOOGLE_TAG_MANAGER_ID || '',
    })
  }

  /** Get sinleton instance */
  static getInstance() {
    if (GTM_handler._instance) return GTM_handler._instance
    GTM_handler._instance = new GTM_handler()
    return GTM_handler._instance
  }

  /** To set doNotTrack to true, e.g. if user is a Wanda employee (using wanda email) */
  set_doNotTrack = (doNotTrack = true) => {
    GTM_handler._doNotTrack = doNotTrack
  }

  /** To determine whether or not to track */
  get_doNotTrack = () => {
    return GTM_handler._doNotTrack
  }

  // Below: Public functions that trigger GTM tracking---------------------------------------------------------------

  /**
   * Only tracked once per session - use GDPR-compliant unique_id (scrambled)
   *
   * @see   {@link https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtm#step_1_update_your_data_layer|docs}.
   *
   * @todo  We need to understand / refactor the existing setup for authentication and user tracking,
   *        (see useAuth.tsx), where GTM is already used to tracking "registerSuccess" and "loginSuccess",
   *        with key "userId" (set to wandaId?!). As we currently haven't access to the GTM container,
   *        useAuth.tsx hasn't been refactored yet (so we don't break existing tracking). The new setup
   *        (with this singleton) is more flexible and maintainable, and should be used for the whole app.
   *        For now, it should be safe to use both setups in parallel (using different keys),
   *        but we should remove the old one once we have access to the GTM container and made adjustments there.
   */
  identify = (unique_id?: string) => {
    if (GTM_handler._user_id || !unique_id?.length) return
    GTM_handler._user_id = unique_id
    TagManager.dataLayer({ dataLayer: { user_id: unique_id, event: 'userIdentified' } })
  }

  /** Generic wrapper for {@link TagManager.dataLayer}, with some filtering and decoration */
  track = (eventName: string, payload: TrackerDTO) => {
    if (GTM_handler._doNotTrack) return

    const extracted = GTM_handler._filterAndExtract(payload)
    if (!extracted) return

    // We decorate the payload with some additional data
    const logThis = {
      _country: payload._country,
      _city: payload._city,
      _page: window.location.pathname,
      _flow: payload._flow,
      _flow_variant: payload._flow_variant || undefined,
      _app_event: eventName,
      // See https://www.analyticsmania.com/post/google-analytics-4-user-id/ :
      // "You must send user_id with every event to GA4 (when user_id is available)".
      user_id: GTM_handler._user_id,
      ...(extracted || {}),
    }

    // Keep for debugging
    /*
    console.groupCollapsed('gtm_handler (SINGLETON) track "%s"', eventName)
    console.log('payload in  (generic):', payload)
    console.log('payload out (for GTM):', logThis)
    console.groupEnd()
    */

    TagManager.dataLayer({ dataLayer: logThis })
  }
}

export const gtm_handler = GTM_handler.getInstance()
