import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function useQueryParam() {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  return params
}

/**
 * A hook that consumes query params from the URL and returns them as an
 * object. The consumed query params are removed from the URL.
 *
 * @param keys - An array of keys to consume from the query params.
 * @returns An object containing the consumed query params.
 **/
export function useConsumeQueryParams<T>({
  keys,
}: { keys: (keyof T)[] }): Partial<Record<keyof T, string | undefined>> {
  const { search } = useLocation()
  const navigate = useNavigate()
  const [values, setValues] = useState<Partial<Record<keyof T, string>>>({} as any)

  useEffect(() => {
    const result: Partial<Record<keyof T, string>> = {}
    const params = new URLSearchParams(search)
    for (const key of keys) {
      const value = params.get(key.toString())
      if (value) {
        result[key] = value
      }
      params.delete(key.toString())
    }
    if (Object.keys(result).length > 0) {
      setValues((old) => ({ ...old, ...result }))
      navigate({ search: params.toString() })
    }
  }, [search])

  return values
}
