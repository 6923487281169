import { companyName, firstName, lastName, orgNumber, phoneNumber } from 'utils/validators'
import * as Yup from 'yup'

import type { FormatMessage } from '../../../utils'

const schema = (formatMessage: FormatMessage) =>
  Yup.object().shape({
    firstName: firstName(formatMessage),
    lastName: lastName(formatMessage),
    phoneNumber: phoneNumber(formatMessage).required(formatMessage({ id: 'validation.required' })),
    companyName: companyName(formatMessage),
    orgNumber: orgNumber(formatMessage),
  })

export { schema }
