import { postalCodeFormatter } from '@wanda-space/noelle'
import type {
  AccountId,
  ItemResponseDto,
  OrderContext,
  OrderResponseDto,
  OrderType,
  PlaceOrderRequestDto,
  ProductResponseDto,
  StorageItemsForOrderlinesRequest,
} from '@wanda-space/types'
import type { OrderLinePayload, OrderLineWithFullProductAndDiscount, Product } from 'api-client'
import type { Locale } from 'i18n'
import type { User } from 'interfaces/user'
import { omit } from 'ramda'

import type { ListingForPickup } from './ducks/sell/sellFlow'
import type { SquareMeterFlowState } from './ducks/squareMeter/types'
import type { StorageFlowState } from './ducks/storage/types'

export type ItemPayload = Pick<StorageItemsForOrderlinesRequest, 'id' | 'name' | 'type'> & {
  orderContext?: OrderContext
  productId?: string
}

export interface ItemPayloadWithProduct extends ItemPayload {
  storageProduct: Product
  image?: string | null
}

export const mapUserProductToOrderLine = (
  product: ProductResponseDto,
  accountId: AccountId
): OrderLineWithFullProductAndDiscount => ({
  product: product,
  quantity: 1,
  accountId,
})

export const mapItemToPayload = (item: ItemPayloadWithProduct | ItemResponseDto): ItemPayload => {
  const { id, name, type } = item
  return { id, name, type }
}

export const mapItemToStorageOrderLine = (
  item: ItemPayloadWithProduct | ItemResponseDto
): OrderLineWithFullProductAndDiscount => ({
  product: item.storageProduct as Product,
  quantity: 1,
  item: mapItemToPayload(item),
})

export const isStorageFlowState = (
  order: StorageFlowState | SquareMeterFlowState | ListingForPickup
): order is StorageFlowState => {
  return Object.prototype.hasOwnProperty.call(order, 'existingItems')
}

export const isSquareMeterFlowState = (
  order: StorageFlowState | SquareMeterFlowState | ListingForPickup
): order is SquareMeterFlowState => {
  return Object.prototype.hasOwnProperty.call(order.orderLines, 'squareMeterProduct')
}

export const mapStorageToOrderDto = (
  type: OrderType,
  pickupOrder: StorageFlowState | SquareMeterFlowState | ListingForPickup,
  newItems: ItemPayload[],
  locale: Locale,
  user: User
): PlaceOrderRequestDto => {
  const isStorageFlow = isStorageFlowState(pickupOrder)
  const isSquareMeterFlow = isSquareMeterFlowState(pickupOrder)

  const storageItemIds = []

  if (!isSquareMeterFlow) {
    storageItemIds.push(...newItems)
  }

  if (isStorageFlow) {
    storageItemIds.push(...pickupOrder.existingItems)
  }

  const { dateAndTime, address, serviceLevel, orderLines, coupon, contactPerson } = pickupOrder

  if (!dateAndTime.date || !dateAndTime.timeslot || !address.countryCode) {
    throw new Error('Missing date or timeslot or country code')
  }

  const orderlinePayload = []

  orderlinePayload.push(...pickupOrder.orderLines.addons, ...orderLines.timeslot)

  if (isSquareMeterFlow && pickupOrder.orderLines.squareMeterProduct) {
    orderlinePayload.push(pickupOrder.orderLines.squareMeterProduct)
  }

  if (!isSquareMeterFlow) {
    orderlinePayload.push(...pickupOrder.orderLines.storage, ...pickupOrder.orderLines.packing)
  }

  return {
    type,
    storageItemIds: storageItemIds.map(({ id }) => id),
    orderDetails: {
      deliveryDate: dateAndTime.date,
      deliveryTimeSlot: {
        from: dateAndTime.timeslot.from,
        to: dateAndTime.timeslot.to,
      },
      extraDeliveryInfo: address.extraDeliveryInfo,
      contactInfo: {
        contactPerson,
        firstName: user.address.firstName,
        lastName: user.address.lastName,
        companyName: address.companyName,
        address: {
          street: address.street,
          city: address.city,
          postalCode: postalCodeFormatter(address.postalCode),
          countryCode: address.countryCode,
        },
        email: user.email,
        mobilePhone: user.address.phoneNumber,
      },
      locale,
      serviceLevelType: serviceLevel,
      floorNumber: address.floorNumber ?? 0,
      elevator: address.elevator,
      coupon,
      itemMix: isSquareMeterFlow ? pickupOrder.itemMix : undefined,
    },

    orderLines: orderlinePayload.map(mapOrderLineToInputDto),
  }
}

export const mapOrderLineToInputDto = (
  orderLine: OrderLineWithFullProductAndDiscount | OrderResponseDto['orderLines'][number]
): OrderLinePayload => ({
  ...omit(['product'], orderLine),
  productId: orderLine.product.id as string,
  accountId: (orderLine as OrderLineWithFullProductAndDiscount).accountId
    ? (orderLine as OrderLineWithFullProductAndDiscount).accountId
    : undefined,
})
