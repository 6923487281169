import type { ListingResponseDto, ServiceLevelType } from '@wanda-space/types'
import type { OrderLineWithFullProductAndDiscount } from 'api-client'
import { useAppSelector } from 'hooks/useAppSelector'
import type { PriceWrapper } from 'interfaces'
import { SliceNames, type reduxSliceKeys, stateKeyMappings } from 'reduxStore/constants'
import { gtm_handler } from 'tracking/gtmHandler'
import { mp_handler } from 'tracking/mixpanelHandler'
import type { TrackerDTO } from 'tracking/types'
import { getDecoratedTrackerDTO, parseListingForPurchaseEvent } from 'tracking/utils'

/**
 * Only use this hook, if you need to track events that we absolutely need to track,
 * and which cannot be tracked by using the logger middleware.
 */
export const useTracker = () => {
  const stateUI = useAppSelector((state) => state.ui || {})

  /**
   * Calling {@link mp_handler.track} with a decorated {@link TrackerDTO} object.
   */
  const track = (eventName: string, payload = {}, flow?: TrackerDTO['_flow']) => {
    const decoratedTrackerDTO = getDecoratedTrackerDTO(payload, stateUI, flow)
    mp_handler.track(eventName, decoratedTrackerDTO)
    // Piggi-backing on same event to track in GTM - we should consider renaming this hook to useTracker
    gtm_handler.track(eventName, decoratedTrackerDTO)
  }

  /**
   * Used several places to select flow - not part of funnels, but valuable for UI tracking
   * Keep for now, as this can be valuable for UI tracking (currently not in demand).
   */
  const selectFlow = (flow: string) => {
    return
    // biome-ignore lint/correctness/noUnreachable: <explanation>
    track('select_flow', { flow })
  }

  /**
   * Only used in storage flow - not part of Mixpanel funnel.
   * Keep for now, as this can be valuable for UI tracking (currently not in demand).
   */
  const selectAddons = (addonsOrderLines: OrderLineWithFullProductAndDiscount[]) => {
    return
    // biome-ignore lint/correctness/noUnreachable: <explanation>
    const addons = addonsOrderLines.map((addon) => addon.product.name)
    track('select_addons', { addons }, stateKeyMappings[SliceNames.STORAGE] as reduxSliceKeys)
  }

  /**
   * Only used in storage flow - not part of Mixpanel funnel.
   * Keep for now, as this can be valuable for UI tracking (currently not in demand).
   */
  const selectCarrying = (serviceLevel: ServiceLevelType) => {
    return
    // biome-ignore lint/correctness/noUnreachable: <explanation>
    track('select_carrying', {
      serviceLevel,
    })
  }

  /**
   * Only used in storage and m2 flow - not part of any Mixpanel funnel.
   * Keep for now, as this can be valuable for UI tracking (currently not in demand).
   */
  const placeOrder = (totalPrice: PriceWrapper) => {
    return
    // biome-ignore lint/correctness/noUnreachable: <explanation>
    const currency = totalPrice.currency
    track('place_order', {
      totalPrice: totalPrice.amount,
      ...(currency && { currency }),
    })
  }

  /**
   * Used to generate and trigger purchase events for listings.
   * Whether or not the event will be considered a "buy now" or "bid" event, depends on the `bid` parameter
   * (see also `_flow`-property of `GA4PurchaseEvent_wrapped`).
   */
  const listingPurchase = (listing: ListingResponseDto, bid = 0) => {
    track('purchase_listing', parseListingForPurchaseEvent(listing, bid))
  }

  return {
    selectFlow,
    selectCarrying,
    selectAddons,
    placeOrder,
    listingPurchase,
  }
}
