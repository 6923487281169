import { shouldPolyfill as shouldPolyfillDateformat } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillPluralrules } from '@formatjs/intl-pluralrules/should-polyfill'
import { captureException } from '@sentry/react'
import { Locale as LocaleKey } from 'i18n'

const pluralrules: Record<LocaleKey, () => Promise<unknown>> = {
  [LocaleKey.English]: async () => import('@formatjs/intl-pluralrules/locale-data/en'),
  [LocaleKey.Norwegian]: async () => import('@formatjs/intl-pluralrules/locale-data/no'),
}

export async function polyfillPluralrules(locale: LocaleKey) {
  try {
    if (!shouldPolyfillPluralrules(locale)) {
      return
    }
    await import('@formatjs/intl-pluralrules/polyfill')
    const loader = pluralrules[locale] || pluralrules[LocaleKey.English]
    return loader()
  } catch (error) {
    captureException(error)
  }
}

const numberformat: Record<LocaleKey, () => Promise<unknown>> = {
  [LocaleKey.English]: async () => import('@formatjs/intl-numberformat/locale-data/en'),
  [LocaleKey.Norwegian]: async () => import('@formatjs/intl-numberformat/locale-data/no'),
}

export async function polyfillNumberFormat(locale: LocaleKey) {
  try {
    if (!shouldPolyfillNumberFormat(locale)) {
      return
    }
    await import('@formatjs/intl-numberformat/polyfill')
    const loader = numberformat[locale] || numberformat[LocaleKey.English]
    return loader()
  } catch (error) {
    captureException(error)
  }
}

const dateformat: Record<LocaleKey, () => Promise<unknown>> = {
  [LocaleKey.English]: async () => import('@formatjs/intl-datetimeformat/locale-data/en'),
  [LocaleKey.Norwegian]: async () => import('@formatjs/intl-datetimeformat/locale-data/no'),
}

export async function polyfillDateformat(locale: LocaleKey) {
  try {
    if (!shouldPolyfillDateformat(locale)) {
      return
    }
    await import('@formatjs/intl-datetimeformat/polyfill')
    const loader = dateformat[locale] || dateformat[LocaleKey.English]
    return Promise.all([loader(), import('@formatjs/intl-datetimeformat/add-all-tz')])
  } catch (error) {
    captureException(error)
  }
}
