import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from '@chakra-ui/react'
import { FieldNames } from 'components/contact-info/types'
import { PhoneNumber } from 'components/ui/Field/PhoneNumber/PhoneNumber'
import { WANDA_LAST_ROUTE } from 'consts'
import { Field, Form, Formik, type FormikValues } from 'formik'
import { useAppSelector } from 'hooks/useAppSelector'
import type { FlattenedDeliveryInfo } from 'interfaces'
import type { UserAddress } from 'interfaces/user'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { patchAddress } from 'reduxStore/ducks/user/user'

import { schema as formSchema } from './form-schema'

export interface CollectUserInfoProps {
  orderAddress?: FlattenedDeliveryInfo
}

export function CollectUserInfo({ orderAddress }: CollectUserInfoProps) {
  const initialValues = {
    firstName: orderAddress?.[FieldNames.FIRST_NAME] ? orderAddress[FieldNames.FIRST_NAME] : '',
    lastName: orderAddress?.[FieldNames.LAST_NAME] ? orderAddress[FieldNames.LAST_NAME] : '',
    phoneNumber: orderAddress?.[FieldNames.PHONE_NUMBER]
      ? orderAddress[FieldNames.PHONE_NUMBER]
      : '',
    contactPerson: '',
    orgNumber: '',
    companyName: '',
  }

  const hasOrderAddress = Boolean(
    initialValues.firstName && initialValues.lastName && initialValues.phoneNumber
  )

  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const countryCode = useAppSelector((state) => state.ui.country)
  const submitting = useAppSelector((state) => state.user.submitting)
  const [isBusinessAccount, setIsBusinessAccount] = useState(false)

  const handleSubmit = async (values: FormikValues) => {
    const { firstName, lastName, phoneNumber, orgNumber, companyName, contactPerson } = values
    let updatedAddress: Partial<UserAddress> = {
      firstName,
      lastName,
      phoneNumber,
      countryCode,
    }

    if (isBusinessAccount) {
      updatedAddress = {
        ...updatedAddress,
        orgNo: orgNumber,
        companyName,
        extraContactPerson: [contactPerson],
      }
    }

    await dispatch(patchAddress(updatedAddress)).unwrap()
    const pathname = localStorage.getItem(WANDA_LAST_ROUTE) as string
    navigate({ pathname }, { replace: true })
  }

  return (
    <>
      <Heading as="h2" fontWeight="normal" my="5" fontSize="2xl">
        {formatMessage({ id: 'finish.signing.up' })}
      </Heading>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema(formatMessage)}
        isInitialValid={hasOrderAddress}
      >
        {({ errors, setFieldValue, handleChange, values, isValid, handleBlur, touched }) => (
          <Form id="collect-user-info">
            <FormControl
              onBlur={handleBlur}
              isInvalid={!!errors.firstName && !!touched.firstName}
              mb="4"
            >
              <FormLabel htmlFor="firstname">{formatMessage({ id: 'word.firstName' })}</FormLabel>
              <Input
                required
                id="firstName"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
                mb={2}
              />
              {!!errors.firstName && <FormErrorMessage>{errors.firstName}</FormErrorMessage>}
            </FormControl>
            <FormControl
              onBlur={handleBlur}
              isInvalid={!!errors.lastName && !!touched.lastName}
              mb="4"
            >
              <FormLabel htmlFor="lastName">{formatMessage({ id: 'word.lastName' })}</FormLabel>
              <Input
                required
                id="lastName"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
                mb={2}
              />
              {!!errors.lastName && <FormErrorMessage>{errors.lastName}</FormErrorMessage>}
            </FormControl>

            <FormControl
              onBlur={handleBlur}
              isInvalid={!!errors.phoneNumber && !!touched.phoneNumber}
              mb="4"
            >
              <FormLabel htmlFor="phoneNumber">
                {formatMessage({ id: 'word.phoneNumber' })}
              </FormLabel>
              <PhoneNumber
                data-testid="phoneNumber"
                placeholder={formatMessage({ id: 'word.phoneNumber' })}
                name="phoneNumber"
                value={values.phoneNumber}
                setFieldValue={(value: string) => {
                  setFieldValue('phoneNumber', value)
                }}
                defaultCountry={countryCode}
              />
              {!!errors.phoneNumber && <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>}
            </FormControl>

            <FormControl my="10">
              <Checkbox
                size="lg"
                colorScheme="ctaBlack"
                isChecked={isBusinessAccount}
                onChange={() => setIsBusinessAccount(!isBusinessAccount)}
              >
                {formatMessage({ id: 'word.ordering.for.company' })}
              </Checkbox>
            </FormControl>
            {isBusinessAccount ? (
              <>
                <FormControl mb="5" onBlur={handleBlur} isInvalid={!!errors.companyName}>
                  <FormLabel htmlFor="companyName">
                    {formatMessage({ id: 'word.firm.name' })}
                  </FormLabel>
                  <Field
                    py="7"
                    as={Input}
                    size="lg"
                    name="companyName"
                    id="companyName"
                    data-testid="company-name"
                  />
                  {!!errors.companyName && (
                    <FormErrorMessage>{errors.companyName}</FormErrorMessage>
                  )}
                </FormControl>
                <FormControl mb="5" onBlur={handleBlur} isInvalid={!!errors.orgNumber}>
                  <FormLabel htmlFor="orgNumber">{formatMessage({ id: 'word.org.no' })}</FormLabel>
                  <Field
                    py="7"
                    as={Input}
                    size="lg"
                    name="orgNumber"
                    id="orgNumber"
                    data-testid="org-no"
                  />
                  {!!errors.orgNumber && <FormErrorMessage>{errors.orgNumber}</FormErrorMessage>}
                </FormControl>
                <FormControl mb="5">
                  <FormLabel htmlFor="contactPerson">
                    {formatMessage({ id: 'word.contact.person' })}
                  </FormLabel>
                  <Field
                    py="7"
                    as={Input}
                    size="lg"
                    name="contactPerson"
                    id="contactPerson"
                    data-testid="contact-person"
                  />
                </FormControl>
              </>
            ) : null}
            <Button
              isLoading={submitting}
              width="100%"
              variant="solid"
              colorScheme="ctaBlack"
              size="lg"
              disabled={isValid === false}
              type="submit"
              data-testid="continue-button"
            >
              {formatMessage({ id: 'word.save' })}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}
