import { OrderType, type PlacedOrderResponseDto, StorageItemState } from '@wanda-space/types'
import { createDeliveryOrder } from 'api-client/lib/routes/orders'
import { Routes } from 'consts/Routes'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { useCheckTimeslotOpen } from 'hooks/useCheckTimeslotOpen'
import { useItems } from 'hooks/useItems'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import type { Stepable } from 'interfaces'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { mapToCreateOrderDto } from 'reduxStore/ducks/returnFlow/mappers'
import {
  applyDiscounts,
  flowState,
  orderSuccessPayloadSelector,
  resetAll,
  setOrderSuccessPayload,
} from 'reduxStore/ducks/returnFlow/reducers'
import { type OneTimePaymentItem, PaymentStepBase } from 'routes/Common/PaymentStepBase'
import { add, getDiscountFromPrice, getStoragePricesForItems, orderlineToPrice } from 'utils'
import { serializeError } from 'utils/errors'
import { getCarryingMethodLabel } from 'utils/getCarryingMethodLabel'

export const PaymentAndSummary = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const state = useAppSelector(flowState)
  const { isAuthenticated } = useAuth()
  const [error, setError] = useState('')
  const returnFlowState = useAppSelector((state) => state.returnFlow)
  const { baseStorageProductByItemType } = useProductsAndCategories({
    couponCode: returnFlowState.coupon,
  })
  const user = useAppSelector((state) => state.user.user)
  const orderSuccessPayload = useAppSelector(orderSuccessPayloadSelector)
  const countryCode = useAppSelector((state) => state.user.user?.countryCode)
  const [createOrderLoading, setCreateOrderLoading] = useState(false)
  const items = useAppSelector((state) => state.returnFlow.items)

  const transportOrderLines = returnFlowState.orderLines.taas

  const [isTimeslotOpen] = useCheckTimeslotOpen({
    orderType: returnFlowState.orderType,
    dateAndTime: state.dateAndTime,
    items,
  })
  const addonsOrderlines = returnFlowState.orderLines.addons
  const timeslotOrderlines = returnFlowState.orderLines.timeslot

  const packingPrice = add(...addonsOrderlines.map(orderlineToPrice))
  const timeslotPrice = add(...timeslotOrderlines.map(orderlineToPrice))
  const taasPrice = add(...transportOrderLines.map(orderlineToPrice))

  const totalOneTimeCost = add(packingPrice, timeslotPrice, taasPrice)

  const { data: allExistingItemsOnUser } = useItems(user, { enabled: isAuthenticated })

  const alreadyStoredItems =
    allExistingItemsOnUser?.filter(({ state }) => state === StorageItemState.STORED) ?? []

  const selectedItemsStoragePrice = baseStorageProductByItemType
    ? getStoragePricesForItems(items, baseStorageProductByItemType)
    : undefined

  const allExistingItemsStoragePrice = baseStorageProductByItemType
    ? getStoragePricesForItems(alreadyStoredItems, baseStorageProductByItemType)
    : undefined

  const createOrder = async () => {
    if (!user) {
      return
    }
    if (orderSuccessPayload) {
      next(orderSuccessPayload)
    }

    const payload = mapToCreateOrderDto({ state, user, countryCode })
    setCreateOrderLoading(true)
    try {
      const response = await createDeliveryOrder(payload)
      dispatch(setOrderSuccessPayload(response))
      next(response)
    } catch (error) {
      setError(serializeError(error).localizationKey)
    } finally {
      setCreateOrderLoading(false)
    }
  }

  const next = ({ orderId }: PlacedOrderResponseDto) => {
    const queryParams = { orderId }
    onNextStep({ queryParams })
    dispatch(resetAll())
  }

  const oneTimeItems: OneTimePaymentItem[] = [
    {
      name: formatMessage({
        id: getCarryingMethodLabel(OrderType.DELIVERY, returnFlowState.serviceLevelType),
      }),
      price: taasPrice.amount,
      oldPrice: taasPrice.oldPrice,
      isTaas: true,
      discount: getDiscountFromPrice(taasPrice)?.amount,
    },
    {
      name: formatMessage({
        id: 'booking.summary.timeslot',
      }),
      price: timeslotPrice.amount,
      oldPrice: timeslotPrice.oldPrice,
      discount: getDiscountFromPrice(timeslotPrice)?.amount,
    },
    {
      name: formatMessage({
        id: 'booking.summary.addon',
      }),
      price: packingPrice.amount,
      oldPrice: packingPrice.oldPrice,
      discount: getDiscountFromPrice(packingPrice)?.amount,
    },
  ]

  return (
    <>
      <PaymentStepBase
        error={error}
        hideYouPayNow
        onObosMembershipActivation={(discounts) => dispatch(applyDiscounts({ discounts }))}
        country={returnFlowState.address.countryCode}
        addCoupon={(discounts, coupon) => applyDiscounts({ discounts, coupon })}
        coupon={returnFlowState.coupon}
        onNextStep={onNextStep}
        totalSubscriptionCost={
          (allExistingItemsStoragePrice?.amount ?? 0) - (selectedItemsStoragePrice?.amount ?? 0)
        }
        amountOfStoredItems={alreadyStoredItems.length - items.length}
        order={{
          address: `${returnFlowState.address.street}, ${returnFlowState.address.city}`,
          ownerName: returnFlowState.contactPerson?.name.length
            ? returnFlowState.contactPerson?.name
            : `${returnFlowState.address.firstName} ${returnFlowState.address.lastName}`,
          date: new Date(state.dateAndTime.date ?? ''),
          timeslot: returnFlowState.dateAndTime.timeslot,
        }}
        disableOfferInvoiceOption={false}
        createOrder={createOrder}
        isSubmittingOrder={createOrderLoading}
        hasActiveOrderLimitReached={false}
        isTimeslotOpen={isTimeslotOpen}
        changeAddress={Routes.ReturnFlowaddress}
        changeDate={Routes.ReturnFlowSchedule}
        totalTransactionPrice={totalOneTimeCost.amount}
        oneTimePayment={{
          defaultCollapsed: false,
          price: totalOneTimeCost.amount,
          oneTimeServices: oneTimeItems,
          oldPrice: totalOneTimeCost.oldPrice,
          items: oneTimeItems,
        }}
      />
    </>
  )
}
