import { Button, Text } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { OrderState, OrderType, UserFeatures } from '@wanda-space/types'
import type { Product } from 'api-client'
import { getUserFeatures } from 'api-client/lib/routes/userFeature'
import { SelectAddonsAddress } from 'components/SelectAddonsAddress/SelectAddonsAddress'
import { SelectCardSkeleton } from 'components/SelectCardSkeleton'
import { AddonsAddressType } from 'consts'
import { useAppSelector } from 'hooks/useAppSelector'
import { useAuth } from 'hooks/useAuth'
import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { useOrders } from 'hooks/useOrders'
import { useProductsAndCategories } from 'hooks/useProductsAndCategories'
import { useTracker } from 'hooks/useTracker'
import type { Stepable } from 'interfaces'
import { isNil } from 'ramda'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { addAddons, removeAddon } from 'reduxStore/ducks/storage/storageFlow'
import { InsuranceAddon } from 'routes/Common/Insurance'
import { flexAddressProductFinder } from 'utils/product-utils'

export const FLEX_ADDRESS = 'product.flex.address'

const Addons = ({ onNextStep }: Stepable) => {
  const { formatMessage } = useIntl()
  const { isAuthenticated } = useAuth()
  const user = useAppSelector((state) => state.user.user)
  const addonsOrderlines = useAppSelector((state) => state.storageFlow.orderLines.addons)
  const coupon = useAppSelector((state) => state.storageFlow.coupon)
  const orderType = useAppSelector((state) => state.storageFlow.orderType)
  const { userProducts, isLoading, isError, error } = useProductsAndCategories({
    couponCode: coupon,
  })
  const dispatch = useAppDispatch()
  const tracker = useTracker()

  const isFlexAdded = Boolean(
    addonsOrderlines.find((addon) => flexAddressProductFinder(addon.product))
  )

  const [addressType, setAddressType] = useState<AddonsAddressType>(
    isFlexAdded ? AddonsAddressType.FLEX : AddonsAddressType.ONE
  )

  const { data: featureFlags } = useFeatureFlags()

  const { data: userFeatures, isInitialLoading: loadingUserFeature } = useQuery(
    ['getUserFeatures'],
    () => getUserFeatures(),
    { enabled: isAuthenticated }
  )

  const { data: orders, isInitialLoading: isLoadingOrders } = useOrders()

  const pendingOrderWithFlexAddressAddon = orders?.find(
    (order) =>
      (order.state === OrderState.ORDER_SUBMITTED ||
        order.state === OrderState.PAYMENT_AUTHORISED) &&
      order.orderLines.find((o) => flexAddressProductFinder(o.product as Product))
  )

  const isFlexAddressActive =
    Boolean(userFeatures?.[UserFeatures.FLEX_ADDRESS]) ||
    typeof pendingOrderWithFlexAddressAddon !== 'undefined'

  const flexAddressProduct = userProducts?.find(flexAddressProductFinder)

  const handleAddressChange = (data: AddonsAddressType) => {
    if (addressType === data || isNil(flexAddressProduct)) {
      return
    }

    setAddressType(data)

    if (flexAddressProduct) {
      dispatch(removeAddon({ productId: flexAddressProduct.id }))

      if (isFlexAdded === false) {
        dispatch(addAddons({ product: flexAddressProduct, accountId: user?.id! }))
      }
    }
  }

  const showAddressAddon =
    featureFlags?.ENABLE_FLEX_ADDRESS && orderType !== OrderType.CUSTOMER_DROP_OFF

  const handleClickOnNextStep = () => {
    onNextStep()
  }

  if (isError) {
    throw error
  }

  return (
    <>
      <InsuranceAddon
        coupon={coupon}
        addonsOrderlines={addonsOrderlines}
        onAddInsurance={(product) => dispatch(addAddons({ product, accountId: user?.id! }))}
        onRemoveInsurance={(product) =>
          dispatch(
            removeAddon({
              productId: product.id,
            })
          )
        }
      />

      {showAddressAddon && (
        <>
          <Text textTransform="uppercase" fontWeight="500" mb={2} fontSize="lg">
            {formatMessage({ id: 'word.addresses' })}
          </Text>
          {loadingUserFeature || isLoadingOrders ? (
            <SelectCardSkeleton />
          ) : (
            <SelectAddonsAddress
              onChange={handleAddressChange}
              addressType={addressType}
              flexProduct={flexAddressProduct}
              isActive={isFlexAddressActive}
            />
          )}
        </>
      )}

      <Button
        width="100%"
        onClick={() => {
          handleClickOnNextStep()
          tracker.selectAddons(addonsOrderlines)
        }}
        isLoading={isLoading}
        size="lg"
        variant="solid"
        colorScheme="ctaBlack"
        data-testid="continue-button"
      >
        {formatMessage({ id: 'booking.review' })}
      </Button>
    </>
  )
}

export { Addons }
