import { Button as ChakraButton } from '@chakra-ui/react'
import { OrderContext, OrderType, ServiceLevelType } from '@wanda-space/types'
import { SelectCardSkeleton } from 'components/SelectCardSkeleton'
import { SelectCarryingMethod } from 'components/SelectCarryingMethod/SelectCarryingMethod'
import { useAppSelector } from 'hooks/useAppSelector'
import { useTaas } from 'hooks/useTaas'
import { useTracker } from 'hooks/useTracker'
import type { Stepable } from 'interfaces'
import React from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from 'reduxStore'
import { combinedItemsSelector } from 'reduxStore/ducks/storage/selectors'
import { setServiceLevel } from 'reduxStore/ducks/storage/storageFlow'
import { getItemPayloadsFromItems } from 'utils/item'

const SelectMethod = ({ onNextStep }: Stepable) => {
  const dispatch = useAppDispatch()
  const serviceLevel = useAppSelector((state) => state.storageFlow.serviceLevel)
  const floorNumber = useAppSelector((state) => state.storageFlow.address.floorNumber)
  const elevator = useAppSelector((state) => state.storageFlow.address.elevator)
  const coupon = useAppSelector((state) => state.storageFlow.coupon)
  const tracker = useTracker()

  const items = useAppSelector(combinedItemsSelector)

  const { curbSideTotalCost, carryingTotalCost, firstDoorTotalCost, isLoading } = useTaas({
    storageItems: getItemPayloadsFromItems(items, OrderContext.STORAGE),
    orderType: OrderType.PICK_UP,
    floorNumber,
    elevator,
    coupon: coupon,
  })

  if (!serviceLevel && !isLoading) {
    dispatch(
      setServiceLevel({
        serviceLevelType:
          typeof firstDoorTotalCost !== 'undefined'
            ? ServiceLevelType.FIRST_DOOR
            : ServiceLevelType.CURBSIDE,
      })
    )
  }

  const { formatMessage } = useIntl()

  const onChange = (serviceLevel: ServiceLevelType) => {
    dispatch(setServiceLevel({ serviceLevelType: serviceLevel }))
  }

  if (!serviceLevel) {
    return (
      <>
        <SelectCardSkeleton />
        <SelectCardSkeleton />
        <SelectCardSkeleton />
      </>
    )
  }

  return (
    <>
      <SelectCarryingMethod
        onChange={onChange}
        carryingPrice={carryingTotalCost}
        curbSidePrice={curbSideTotalCost}
        serviceLevel={serviceLevel}
        firstDoorPrice={firstDoorTotalCost}
      />

      <ChakraButton
        width="100%"
        onClick={() => {
          onNextStep()
          tracker.selectCarrying(serviceLevel)
        }}
        isLoading={isLoading}
        size="lg"
        variant="solid"
        colorScheme="ctaBlack"
        data-testid="continue-button"
      >
        {formatMessage({ id: 'word.continue' })}
      </ChakraButton>
    </>
  )
}

export { SelectMethod }
