import { Container, Tag, Text } from '@chakra-ui/react'
import { ProgressIndicator } from '@wanda-space/noelle'
import { OrderType, ServiceLevelType } from '@wanda-space/types'
import { BookingHeader } from 'components/BookingHeader'
import { FlowStepRoutes } from 'components/FlowStepRoutes'
import { Routes as routes } from 'consts'
import { useDateLocale } from 'contexts/Intl'
import { useAppSelector } from 'hooks/useAppSelector'
import { useSteps } from 'hooks/useCurrentStep'
import { useFlowPrices } from 'hooks/useFlowPrices'
import { useProgressIndicatorDeliveryPriceSummary } from 'hooks/useProgressIndicatorPriceSummary'
import { useTaas } from 'hooks/useTaas'
import type { FlowStep } from 'interfaces/flows'
import { isEmpty } from 'ramda'
import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore'
import { dateTimeSelector, setTaasOrderLines } from 'reduxStore/ducks/returnFlow/reducers'
import { sortCurrentStepToLast } from 'utils/bulletPoints'
import { getItemPayloadsFromItems } from 'utils/item'
import { getTaasOrderLineForNewItemsByServiceLevelType } from 'utils/orderline'
import { formatPriceWrapper } from 'utils/price-utils'

import { useFeatureFlags } from 'hooks/useFeatureFlags'
import { Address } from './Address'
import { BookingConfirmation } from './BookingConfirmation'
import { Carrying } from './Carrying'
import { PaymentAndSummary } from './PaymentAndSummary'
import { SelectItems } from './SelectItems'
import { SelectDateAndTime as SelectTimeAndDate } from './SelectTimeAndDate'
import { SelectTransportOrWarehouseVisit } from './SelectTransportOrWarehouseVisit'
import { bulletPoints, returnFlowPaths } from './paths'

export const ReturnFlow = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { data: featureFlags } = useFeatureFlags()
  const flow = useAppSelector((state) => state.returnFlow)
  const { orderType, items, orderLines, serviceLevelType, address, coupon } = flow
  const dateTime = useAppSelector(dateTimeSelector)
  const { taasPrice } = useFlowPrices(orderLines)
  const estimatedTimeslotCost = useAppSelector((state) => state.ui.estimatedTimeslotCost)
  const { data: taasResponse } = useTaas({
    storageItems: getItemPayloadsFromItems(items),
    orderType,
    floorNumber: address.floorNumber,
    elevator: address.elevator,
    coupon: coupon,
  })
  const dateLocale = useDateLocale()

  useEffect(() => {
    if (taasResponse) {
      dispatch(
        setTaasOrderLines(
          getTaasOrderLineForNewItemsByServiceLevelType(
            serviceLevelType ?? ServiceLevelType.FIRST_DOOR,
            taasResponse.orderline
          )
        )
      )
    }
  }, [serviceLevelType, taasResponse?.orderline.carrying, taasResponse?.orderline.curbside, items])

  const stepTitle = (key: string) =>
    formatMessage({ id: `returnFlow.steps.${orderType.toLowerCase()}.${key}.title` })

  const steps: FlowStep[] = [
    {
      element: SelectItems,
      path: returnFlowPaths.selectItems,
      title: stepTitle('select-items'),
    },
  ]
  if (featureFlags?.ENABLE_SPACESHIP_WAREHOUSE_VISIT) {
    steps.push({
      element: SelectTransportOrWarehouseVisit,
      path: returnFlowPaths.transport,
      title: stepTitle('select-transport'),
      fallback: () => items.length === 0,
    })
  }

  steps.push({
    element: SelectTimeAndDate,
    path: returnFlowPaths.schedule,
    title: stepTitle('select-time'),
    fallback: () => items.length === 0,
  })

  if (orderType === OrderType.DELIVERY) {
    steps.push(
      {
        element: Address,
        path: returnFlowPaths.address,
        title: stepTitle('address'),
        fallback: () => isEmpty(dateTime),
      },
      {
        element: Carrying,
        path: returnFlowPaths.carrying,
        title: stepTitle('carrying'),
        fallback: () => isEmpty(dateTime),
      }
    )
  }

  steps.push(
    {
      element: PaymentAndSummary,
      path: returnFlowPaths.summary,
      title: stepTitle('summary'),
      description: '',
      requireLogin: true,
      fallback: () => isEmpty(dateTime),
    },
    {
      element: BookingConfirmation,
      path: returnFlowPaths.confirm,
      requireLogin: true,
    }
  )

  const {
    currentStep,
    FallBackNavigate,
    StepGuard,
    onNextStep,
    nextStep,
    previousStep,
    isLastStep,
    progress,
    furthestStepReached,
  } = useSteps(steps, `${routes.ReturnFlow}/`)
  const taasSummary = useProgressIndicatorDeliveryPriceSummary({
    amount: taasPrice.amount + estimatedTimeslotCost.amount,
    currency: taasPrice.currency,
  })

  if (FallBackNavigate) {
    return <FallBackNavigate />
  }

  if (StepGuard) {
    return <StepGuard />
  }
  return (
    <>
      <BookingHeader
        title={currentStep.title}
        description={currentStep.description}
        onBack={previousStep && !isLastStep ? () => navigate(-1) : undefined}
        indicator={
          isLastStep ? undefined : (
            <ProgressIndicator
              key={taasPrice.amount}
              size="large"
              progress={progress}
              taasSummary={taasSummary}
              itemSummary={`${items.length} ${formatMessage(
                { id: 'word.itemsPlural' },
                { length: items.length }
              )}`}
              bulletPoints={sortCurrentStepToLast(
                bulletPoints(formatMessage, flow, furthestStepReached, dateLocale),
                currentStep
              )}
              bulletPointsProps={{
                maxWidth: 'min(calc(100vw - 40px), 560px)',
                minWidth: 'min(calc(100vw - 40px), 560px)',
              }}
            >
              <Text lineHeight="1.6rem">
                {formatMessage({ id: 'booking.indicator.expanded.summary' })}
                <>
                  {formatMessage({ id: 'booking.indicator.expanded.summary.taas' })}
                  <Tag bg="purple.200" mx="1">
                    {formatPriceWrapper({
                      amount: taasPrice.amount + estimatedTimeslotCost.amount,
                      currency: taasPrice.currency,
                    })}
                  </Tag>
                </>
              </Text>
            </ProgressIndicator>
          )
        }
      />
      <Container pb="20">
        <FlowStepRoutes
          steps={steps}
          currentStep={currentStep}
          onNextStep={onNextStep}
          flowRootPath={routes.ReturnFlow}
          nextStep={nextStep}
        />
      </Container>
    </>
  )
}
