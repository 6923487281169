import { SupportedCities, SupportedCountries } from '@wanda-space/types'
import { getEnv } from 'env'

export enum Locale {
  English = 'en-GB',
  Norwegian = 'no-NO',
}

export type Locales = Record<Locale, Record<string, string>>

export function isValidLocale(value: string): value is Locale {
  return (Object.values(Locale) as string[]).includes(value)
}

export function mapStringToLocale(input: string | undefined): Locale {
  if (input) {
    if (isValidLocale(input)) {
      return input
    }
    if (input.includes('en')) {
      return Locale.English
    }
  }
  return Locale.Norwegian
}

export function mapLocaleToShortString(locale: Locale) {
  return locale.split(/[-_]/)[0] as 'en' | 'no'
}

export function countryToLocale(region: string): Locale {
  return Locale.Norwegian
}

const DEFAULT_CITIES: Record<SupportedCountries, SupportedCities> = {
  NO: SupportedCities.NO_OSLO,
  SE: SupportedCities.SE_STOCKHOLM,
}

const DEFAULT_POSTAL_CODES: Record<SupportedCountries, string> = {
  NO: '0010',
  SE: '10770',
}

export const DEFAULT_LANGUAGE = countryToLocale(getEnv('USER_REGION'))
export const DEFAULT_COUNTRY =
  SupportedCountries[getEnv('USER_REGION').toUpperCase() as SupportedCountries] ??
  SupportedCountries.NO

export const DEFAULT_CITY = DEFAULT_CITIES[DEFAULT_COUNTRY]
export const DEFAULT_POSTAL_CODE = DEFAULT_POSTAL_CODES[DEFAULT_COUNTRY]
